import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardJobMinimal } from 'components/organisms';
import {Link} from 'gatsby';
import Jobdpop from 'components/Popups/Jobpop/Jobdpop'
import { Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  
  fontWeight900: {
    fontWeight: 900,
  },
}));


const Jobs = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={
          <span>


              <Typography variant="inherit" component="span"> Check out our open Job  </Typography>
              <Typography color="secondary" variant="h3" component="span" className={classes.fontWeight900} >opportunities</Typography>
          </span>
      }

        subtitleColor="textPrimary"
        ctaGroup={[
          <Button href='/Ourstory' color="primary" size="large" variant="contained">
            About the company
          </Button>,
        ]}
        data-aos="fade-up"
      />
      <Grid container spacing={isMd ? 4 : 2}>


        <Grid item xs={12} >

          <Link to ="/Joba">
          <CardJobMinimal
            title={
              <span>
    
    
                  <Typography color = "textPrimary" variant="inherit" component="span"> Territory Sales Manager </Typography>

              </span>
          }
            subtitle="Fulltime -
            Chennai , India"
            showArrow = {    <Jobdpop/>}
            titleProps={{
              variant: 'h6',
            }}
            subtitleProps={{
              variant: 'subtitle1',
            }}
        
          />
         
          </Link>
  
        </Grid>
        <Grid item xs={12} >
          <Link to="/Fse">
            <CardJobMinimal
               title={
                <span>
      
      
                    <Typography color = "textPrimary" variant="inherit" component="span"> Field Service Engineer</Typography>
  
                </span>
            }
              subtitle="Fulltime -
              Chennai , India"
              showArrow
              titleProps={{
                variant: 'h6',
              }}
              subtitleProps={{
                variant: 'subtitle1',
              }}
            />
          </Link>

        </Grid>

        <Grid item xs={12} >
          <Link to="/Fas">
            <CardJobMinimal
            title={
              <span>
    
    
                  <Typography color = "textPrimary" variant="inherit" component="span"> Field Application Scientist </Typography>

              </span>
          }
              subtitle="Fulltime-
              Ahemdabad , India"
              showArrow
              titleProps={{
                variant: 'h6',
              }}
              subtitleProps={{
                variant: 'subtitle1',
              }}
            />
          </Link>

        </Grid>
        <Grid item xs={12} >
          <Link to ="/Cse">
            <CardJobMinimal
                  title={
                    <span>
          
          
                        <Typography color = "textPrimary" variant="inherit" component="span"> Customer Support Executive </Typography>
      
                    </span>
                }
              subtitle="Fulltime-
              Delhi, India"
              showArrow
              titleProps={{
                variant: 'h6',
              }}
              subtitleProps={{
                variant: 'subtitle1',
              }}
            />
          </Link>

        </Grid>

      </Grid>
    </div>
  );
};

Jobs.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Jobs;
