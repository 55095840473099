import React from 'react';

import Gallery from 'react-grid-gallery';

const IMAGES =
    [
    {
        src: "https://onwhicar.sirv.com/premas/main/people/Priority2.jpg",
        thumbnail: "https://onwhicar.sirv.com/premas/main/people/Priority2.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        // tags: [{ value: "Ocean", title: "Ocean" }, { value: "People", title: "People" }],
        // caption: "Boats (Jeshu John - designerspics.com)"
    },
    {
        src: "https://onwhicar.sirv.com/premas/main/people/LRM_EXPORT_29731947359915_20190705_002835450.jpeg",
        thumbnail: "https://onwhicar.sirv.com/premas/main/people/LRM_EXPORT_29731947359915_20190705_002835450.jpeg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        // tags: [{ value: "Ocean", title: "Ocean" }, { value: "illumina", title: "award" }],
        // caption: "Boats (Jeshu John - designerspics.com)"
    },
    {
        src: "https://onwhicar.sirv.com/premas/main/people/Priority1.jpg",
        thumbnail: "https://onwhicar.sirv.com/premas/main/people/Priority1.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        // tags: [{ value: "Ocean", title: "Ocean" }, { value: "illumina", title: "award" }],
        // caption: "Boats (Jeshu John - designerspics.com)"
    },
    {
        src: "https://onwhicar.sirv.com/premas/main/people/LRM_EXPORT_30548209693985_20190705_004211830.jpeg",
        thumbnail: "https://onwhicar.sirv.com/premas/main/people/LRM_EXPORT_30548209693985_20190705_004211830.jpeg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        // tags: [{ value: "Ocean", title: "Ocean" }, { value: "illumina", title: "award" }],
        // caption: "Boats (Jeshu John - designerspics.com)"
    },
  
    {
        src: "https://onwhicar.sirv.com/premas/main/people/LRM_EXPORT_14221394877170_20190716_163610572.jpeg",
        thumbnail: "https://onwhicar.sirv.com/premas/main/people/LRM_EXPORT_14221394877170_20190716_163610572.jpeg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        // tags: [{ value: "Ocean", title: "Ocean" }, { value: "People", title: "People" }],
        // caption: "Boats (Jeshu John - designerspics.com)"
    },
    // {
    //     src: "https://ik.imagekit.io/j6phbjneuop/LRM_EXPORT_14221394877170_20190716_163610572_JnvqY_YjQ.jpeg?updatedAt=1629539895633",
    //     thumbnail: "https://ik.imagekit.io/j6phbjneuop/LRM_EXPORT_14221394877170_20190716_163610572_JnvqY_YjQ.jpeg?updatedAt=1629539895633",
    //     thumbnailWidth: 320,
    //     thumbnailHeight: 212,
    //     // tags: [{ value: "Ocean", title: "Ocean" }, { value: "People", title: "People" }],
    //     // caption: "Boats (Jeshu John - designerspics.com)"
    // },



    ]

export default class Sample extends React.Component {
    render() {
        return (
            <Gallery images={IMAGES} />
        );
    }
}