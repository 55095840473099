import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    background: 'url(https://onwhicar.sirv.com/premas/main/people/1.jpeg) no-repeat center',
    backgroundSize: 'cover',
  },
  fontWeight900: {
    fontWeight: 900,
  },
  section: {
    background: "#000000",
    opacity : '0.7',
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
      marginRight: 0,
      padding: theme.spacing(9),
    },
  },
  textWhite: {
    color: 'white',
  },
}));

const Head = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Section>
        <Section className={classes.section}>
          <SectionHeader
            disableGutter
            data-aos="fade-up"
            align="left"
            titleVariant="h3"
            title={
              <span className={clsx(classes.textWhite , classes.fontWeight900)}>Why join us?</span>
            }
            subtitle={
              <span className={classes.textWhite}>
There are Jobs, and then there are Careers. The change to do the kind of work that adds something meaningful, the opportunity to challenge yourself and learn new skills, the prospect to work day-in and out with smart, zealous, and motivated people. If that's what excites you, then join PLS!
              </span>
            }
            ctaGroup={[
              <Button variant="contained" size="large">
              Connect with our HR team
              </Button>,
            ]}
          />
        </Section>
      </Section>
    </div>
  );
};

Head.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Head;
